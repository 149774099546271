<template>
  <div>
    <Slides v-bind:scrollingCards="scrollingCards" />
  </div>
</template>

<script>
import Slides from "@/components/Slides";

export default {
  name: "courses",

  components: { Slides },

  data: () => ({
    // Description of the Cards to show in the page
    scrollingCards: [
      {
        id: "1",
        class: "secondary",
        layoutColor: "",
        height: "50vh",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: 1,
          orderMd: 1,
          isOn: true,
          cols: 10,
          md: 10,
          lg: 10,
          xl: 10,
          textCardContainerClasses: "elevation-0",
          textCardContainerColor: "transparent",
          title: "courses.slide1.title",
          titleClasses: "text-left primary--text text-size-1 font-weight-light",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
        right: {
          order: 2,
          orderMd: 2,
          isOn: false,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
      },
      {
        id: "2",
        class: "",
        height: "",
        image: require("../assets/Angular_certificate.webp"),
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
      },
      {
        id: "3",
        class: "tertiary flat elevation-0 py-8",
        height: "",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: 1,
          orderMd: 1,
          isOn: true,
          cols: 10,
          md: 5,
          lg: 3,
          xl: 2,
          textCardContainerClasses: "elevation-0 mt-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "courses.slide3.left.title",
          titleClasses:
            "text-no-wrap contrast--text text-left text-size-2 font-weight-light",
          subtitle: "courses.slide3.left.subtitle",
          subtitleClasses:
            "contrast--text text-left text-size-3 font-weight-light",
          content: "courses.slide3.left.content",
          contentClasses: "primary--text text-left text-size-3 font-weight-light",
        },
        right: {
          order: 2,
          orderMd: 2,
          isOn: true,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "elevation-0 mb-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "none",
          titleClasses: "",
          subtitle: "courses.slide3.right.subtitle",
          subtitleClasses:
            "text-left contrast--text text-size-3 font-weight-light",
          content: "courses.slide3.right.content",
          contentClasses:
            "text-left primary--text text-size-3 font-weight-light",
        },
      },
      {
        id: "4",
        class: "",
        height: "",
        image: require("../assets/Coursera_certificate.webp"),
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
      },
      {
        id: "5",
        class: "tertiary flat elevation-0 py-8",
        height: "",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: 2,
          orderMd: 1,
          isOn: true,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "elevation-0 mb-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "none",
          titleClasses: "",
          subtitle: "courses.slide5.left.subtitle",
          subtitleClasses:
            "text-left contrast--text text-size-3 font-weight-light",
          content: "courses.slide5.left.content",
          contentClasses:
            "text-left primary--text text-size-3 font-weight-light",
        },
        right: {
          order: 1,
          orderMd: 2,
          isOn: true,
          cols: 10,
          md: 5,
          lg: 3,
          xl: 2,
          textCardContainerClasses: "elevation-0 mt-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "courses.slide5.right.title",
          titleClasses:
            "text-no-wrap contrast--text text-left text-size-2 font-weight-light",
          subtitle: "courses.slide5.right.subtitle",
          subtitleClasses:
            "contrast--text text-left text-size-3 font-weight-light",
          content: "courses.slide5.right.content",
          contentClasses: "primary--text text-left text-size-3 font-weight-light",
        },
      },
    ],
  }),
};
</script>
